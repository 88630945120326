<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Edit User
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>

            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-8 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">


                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="container-fluid my-4">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                                <b-form-group id="example-input-group-1" label="Name"
                                                    label-for="example-input-1">
                                                    <b-form-input id="example-input-1" placeholder="Enter Username"
                                                        name="example-input-1" v-model="$v.form.name.$model"
                                                        :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                        aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.name.$error" class="invalid-feedback">
                                                        <div v-if="!$v.form.name.required">Username is required</div>
                                                        <div v-if="!$v.form.name.maxLength">Maximum limit 100
                                                            characters</div>
                                                        <div v-if="!$v.form.name.alpha">Type alphabets only</div>
                                                    </div>

                                                </b-form-group>



                                                <!-- </div> -->
                                            </div>


                                            <div class="col-md-12">
                                                <b-form-group id="input-group-3" label="Type" label-for="input-3">
                                                    <b-form-select id="input-3" v-model="$v.form.type.$model"
                                                        :state="$v.form.type.$dirty ? !$v.form.type.$error : null"
                                                        :options="types"></b-form-select>

                                                    <div v-if="$v.form.type.$error" class="invalid-feedback">
                                                        <span v-if="!$v.form.type.required">Type is required</span>
                                                    </div>
                                                </b-form-group>



                                            </div>


                                        </div>

                                        <div class="text-right mt-3">
                                            <b-button :disabled='is_loading' type="submit" variant="success"
                                                class="btn believe-btn btn-block">
                                                <i
                                                    :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                                Submit
                                            </b-button>
                                        </div>
                                    </div>
                                </b-form>


                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength, sameAs, helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Z\s]*$/);
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
import Goal from "@/components/Goal.vue";
import Feeling from "@/components/Feeling.vue";
import AudioFile from "@/components/AudioFile.vue";
import { VueEditor } from "../../../../node_modules/vue2-editor";
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            pageNumber : "", // search &
            msg: "",
            is_loading: false,
            types: [
                { text: 'Choose Type...', value: null, selected: true, disabled: true, },
                { text: 'Admin', value: 'admin' },
                { text: 'User', value: 'user' }
            ],
            form: {
                type: null,
                id: null,
                name: null,
            }
        }
    },

    components: {
        VueEditor,
        Tag,
        AudioFile, Goal,
        Feeling,
        // simpleSnotifport { helpers } from 'vuelidate/lib/validators'
    },
    validations: {
        form: {
            name: {
                required,
                maxLength: maxLength(100),
                alpha
            },
            type: {
                required,
            },
        }
    },
    mounted() {
        // window.addEventListener("keypress", function (e) {
        //     if (e.code == "Enter") {
        //         e.preventDefault()
        //     }
        // }.bind(this));
        this.items();
    },

    methods: {

        async items() {
            let data = await this.$store.getters['getEditFormData'];
            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }
            this.pageNumber = data?.pageNumber,
            this.form.name = data?.name
            this.form.type = data?.type
            this.form.id = data?.id
            data?.type == "Admin" ? this.form.type[1].selected = true : this.form.type[2].selected = true;
        },



        goBack() {
            // const category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
            // let prev_url = `/users/list`;

            let prev_url = this.$store.state.prev_url;
            if (prev_url == null || prev_url == "") {
                prev_url = localStorage.getItem('prev_url');
            }
            // if (this.$store.state.prev_url != "" && this.$store.state.prev_url != null) {
            //     prev_url = this.$store.state.prev_url
            //     this.$store.state.prev_url = ""
            //     console.log("state prev url :::: " , this.$store.state.prev_url);
            // }
            // console.log("from edit modal page ::::: " , prev_url);
            this.$store.state.pageNumberAfterAction = this.pageNumber; // search &
            this.$router.push({ path: prev_url });
        },




        async onSubmit() {
            this.$v.form.$touch()

            if (this.$v.form.$anyError) {
                return false;
            }
            try {
                this.is_loading = true;
                let endpoint = "/admin/edit-user";
                const formData = new FormData();
                formData.append("user_id", this.form?.id);
                formData.append("name", this.form?.name);
                formData.append("type", this.form?.type);
                let result = await api.post(endpoint, formData);

                if (result.status == 200) {
                    const msg = "User Updated Successfully";
                    this.$store.dispatch('setMessage', { msg });
                    this.$store.state.pageNumberAfterAction = this.pageNumber; // search &
                    let prev_url = this.$store.state.prev_url;
                    if (prev_url == null || prev_url == "") {
                        prev_url = localStorage.getItem('prev_url');
                    }
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;
                }

            } catch (error) {
                console.log(error?.message);
                this.$swal('Something went wrong!', error?.message, 'error')
                this.is_loading = false;
            }
        },



    },




}
</script>